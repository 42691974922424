










































import {
  defineComponent,
  useRouter,
  useContext,
  computed
} from '@nuxtjs/composition-api'
import { useUser } from '@wemade-vsf/customer';
import { useUiState } from '@wemade-vsf/composables';
import { SfButton } from '@storefront-ui/vue'
import DropdownMenu from 'components/theme/UI/DropdownMenu.vue';
import type { MenuItem } from 'components/theme/UI/DropdownMenu.vue';

export default defineComponent({
  name: 'AccountIcon',
  components: {
    SfButton,
    DropdownMenu
  },
  props: {
    icon: {
      type: String,
      default: 'user'
    },
    loggedInIcon: {
      type: String,
      default: 'circle-user'
    },
    iconHeight: {
      type: String,
      default: '1.6875rem'
    },
    iconWidth: {
      type: String,
      default: '1.6875rem'
    }
  },
  setup (props) {
    const { app, i18n } = useContext()
    const router = useRouter()
    const { isAuthenticated, logout } = useUser()
    const { toggleLoginModal } = useUiState()
    const linkText = computed(() => {
      return isAuthenticated.value
        ? i18n.t('My User Account')
        : i18n.t('Login')
    })

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    }

    const accountIcon = computed(() => {
      return isAuthenticated.value ? props.loggedInIcon : props.icon
    })

    const defaultMenuGroups = [
      [
        {
          label: 'My Profile',
          to: ({ name: 'customer-my-profile' })
        },
        {
          label: 'Addresses details',
          to: ({ name: 'customer-addresses-details' })
        },
        {
          label: 'Order history', 
          to: ({ name: 'customer-order-history' })
        }
      ]
    ]

    const logoutGroup = [
      {
        label: 'Log out',
        icon: 'arrow-right-from-bracket',
        click: async () => {
          await logout({})
          await router.push(app.localeRoute({ name: 'home' }))
        }
      }
    ]

    const menuGroups = computed(() => {
      const injected: MenuItem[][] | undefined = app.$wm.customer?.accountMenuGroups;
      if (injected?.length) {
        return [
          ...injected,
          logoutGroup
        ]
      }
      return [
        ...defaultMenuGroups,
        logoutGroup
      ]
    })

    return {
      handleAccountClick,
      isAuthenticated,
      linkText,
      accountIcon,
      menuGroups
    }
  }
})
