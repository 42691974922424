























































import {
  defineComponent,
  ref
} from '@nuxtjs/composition-api';
import type { RawLocation } from 'vue-router';
import type { PropType } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

export interface MenuItem {
  label?: string;
  icon?: string;
  to?: RawLocation;
  href?: string;
  click?: Function;
  disabled?: boolean;
}

export default defineComponent({
  name: 'DropdownMenu',
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'left'
    },
    items: {
      type: Array as PropType<MenuItem[][]>,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SfButton
  },
  setup () {
    const open = ref(false);

    function closeMenu (event: Event) {
      if (!(event.target as HTMLElement).closest('.dropdown-menu')) {
        open.value = false;
        document.removeEventListener('click', closeMenu);
      }
    }

    function toggleMenu () {
      open.value = !open.value;
      if (open.value) {
        document.addEventListener('click', closeMenu);
      } else {
        document.removeEventListener('click', closeMenu);
      }
    }

    function onItemClick(item: MenuItem) {
      if (item.click) {
        item.click();
      }
      open.value = false;
    }

    function itemProps(item: MenuItem) {
      return {
        'click.native': () => open.value = false,
        ...item.click ? {
          click: item.click
        } : []
      }
    }

    return {
      open,
      toggleMenu,
      onItemClick,
      itemProps
    }
  }
})
